import gql from 'graphql-tag';

export const GQL_FETCH_PAGE_METADATA_ENTRY = gql`
  query ($locale: String!, $slug: String!, $preview: Boolean!) {
    page: pageCollection(
      limit: 1
      where: { contentfulMetadata: { tags: { id_contains_some: ["vp"] } }, languageCode: $locale, slug: $slug }
      preview: $preview
    ) {
      items {
        slug
        languageCode
        seoMetadata {
          title
          description
          previewImage {
            url
          }
          index
          follow
        }
      }
    }

    translatedPages: pageCollection(
      limit: 1
      where: { contentfulMetadata: { tags: { id_contains_some: ["vp"] } }, languageCode: "EN", slug: $slug }
      preview: $preview
    ) {
      items {
        translationsCollection(limit: 7) {
          items {
            sys {
              id
              firstPublishedAt
            }
            pageName
            slug
            languageCode
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_SITEMAPS_DATA = gql`
  query ($preview: Boolean!) {
    postCollection(
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_some: ["vp"], id_contains_none: ["translation"] } } }
    ) {
      items {
        slug
        languageCode
        sys {
          publishedAt
        }
        translationsCollection {
          items {
            languageCode
            slug
          }
        }
      }
    }
    pageCollection(
      preview: $preview
      where: { contentfulMetadata: { tags: { id_contains_some: ["vp"], id_contains_none: ["translation"] } } }
    ) {
      items {
        slug
        languageCode
        sys {
          publishedAt
        }
      }
    }
  }
`;

// =============================================
//              CANONICAL QUERY
// =============================================
export const GQL_FETCH_CANONICAL_DATA = gql`
  query {
    postCollection(
      where: { contentfulMetadata: { tags: { id_contains_some: ["vp"], id_contains_none: ["translation"] } } }
    ) {
      items {
        slug
        seoMetadata {
          canonical
        }
        translationsCollection(limit: 7) {
          items {
            languageCode
            slug
            seoMetadata {
              canonical
            }
          }
        }
      }
    }
    pageCollection(
      where: { contentfulMetadata: { tags: { id_contains_some: ["vp"], id_contains_none: ["translation"] } } }
    ) {
      items {
        slug
        seoMetadata {
          canonical
        }
      }
    }
  }
`;

export const GQL_FETCH_TOP_NAVIGATION_ENTRY = gql`
  query ($locale: String!, $preview: Boolean!) {
    topNavigation: topNavigationCollection(
      limit: 1
      where: { contentfulMetadata: { tags: { id_contains_some: ["vp"] } }, languageCode: $locale }
      preview: $preview
    ) {
      items {
        logo {
          url
          width
          height
          description
        }
        navItemsCollection(order: order_ASC) {
          items {
            sys {
              id
            }
            slug
            pageName
            languageCode
            subPagesCollection(order: order_ASC) {
              items {
                sys {
                  id
                }
                languageCode
                slug
                pageName
                description
              }
            }
          }
        }
      }
    }

    globalTextBlock: globalTextBlocksCollection(
      limit: 1
      where: { languageCode: $locale, contentfulMetadata: { tags: { id_contains_some: ["vp"] } } }
    ) {
      items {
        allProducts
        pages
        blogArticles
        lastUpdated
        noResultsFor
      }
    }
  }
`;

export const GQL_FETCH_FOOTER_NAVIGATION_ENTRY = gql`
  query ($locale: String!, $preview: Boolean!) {
    footer: footerCollection(
      limit: 1
      where: { contentfulMetadata: { tags: { id_contains_some: ["vp"] } }, languageCode: $locale }
      preview: $preview
    ) {
      items {
        logo {
          url
          width
          height
          description
        }
        navItemsCollection(order: order_ASC) {
          items {
            sys {
              id
            }
            slug
            pageName
            subPagesCollection(order: order_ASC) {
              items {
                sys {
                  id
                }
                slug
                pageName
                description
              }
            }
          }
        }
        health
        performance
        tactical
        copyright
        allRightsReserved
        privacyPolicy
        loginToValdHub
      }
    }
  }
`;

export const GQL_FETCH_TOTAL_POST_ENTRY = gql`
  query ($preview: Boolean!) {
    postCollection(preview: $preview, where: { contentfulMetadata: { tags: { id_contains_some: ["vp"] } } }) {
      total
    }
  }
`;

export const GQL_FETCH_ANNOUNCEMENT_ENTRIES = gql`
  query ($locale: String!, $preview: Boolean!) {
    eBooks: announcementCollection(
      limit: 1
      where: { languageCode: $locale, contentfulMetadata: { tags: { id_contains_some: ["vp"] } } }
      preview: $preview
    ) {
      items {
        title
        topicCollection {
          items {
            sys {
              id
            }
            bodyCopy {
              json
            }
          }
        }
      }
    }
  }
`;

export const GQL_FETCH_LIVE_PREVIEW_PAGE_ENTRY = gql`
  query ($entryId: String!) {
    pageCollection(
      where: { sys: { id: $entryId }, contentfulMetadata: { tags: { id_contains_some: ["vp"] } } }
      limit: 1
      preview: true
    ) {
      items {
        pageName
        slug
        languageCode
      }
    }
  }
`;

export const QGL_PAGINATED_POST_ENTRIES = gql`
  query (
    $skip: Int!
    $limit: Int
    $categorySlug: String
    $productName: String
    $locale: String!
    $slug: String!
    $preview: Boolean!
  ) {
    page: pageCollection(
      limit: 1
      where: { contentfulMetadata: { tags: { id_contains_some: ["vp"] } }, languageCode: $locale, slug: $slug }
      preview: $preview
    ) {
      items {
        slug
        pageName
        sys {
          publishedAt
          firstPublishedAt
        }
      }
    }

    blogs: postCollection(
      skip: $skip
      limit: $limit
      order: datePublished_DESC
      preview: $preview
      where: {
        languageCode: $locale
        AND: [{ categories: { slug: $categorySlug } }, { categories: { slug: $productName } }]
        contentfulMetadata: { tags: { id_contains_some: ["vp"] } }
      }
    ) {
      total
      items {
        sys {
          id
        }
        slug
        categoriesCollection(limit: 1) {
          items {
            title
            slug
          }
        }
        title
        thumbnail {
          url
          width
          height
          title
          description
          contentType
        }
        datePublished
        body {
          json
        }
        featuredImage {
          url
        }
        excerpt
      }
    }

    globalTextBlock: globalTextBlocksCollection(
      limit: 1
      where: { languageCode: $locale, contentfulMetadata: { tags: { id_contains_some: ["vp"] } } }
    ) {
      items {
        learnMore
        noDataFound
      }
    }
  }
`;

export const GQL_FETCH_NEWS_AND_RESEARCH_PAGE_TITLE = gql`
  query ($locale: String!, $slug: String!, $preview: Boolean!) {
    page: pageCollection(
      limit: 1
      where: { contentfulMetadata: { tags: { id_contains_some: ["vp"] } }, languageCode: $locale, slug: $slug }
      preview: $preview
    ) {
      items {
        slug
        pageName
        sys {
          publishedAt
          firstPublishedAt
        }
      }
    }

    globalTextBlock: globalTextBlocksCollection(
      limit: 1
      where: { languageCode: $locale, contentfulMetadata: { tags: { id_contains_some: ["vp"] } } }
    ) {
      items {
        learnMore
        noDataFound
      }
    }
  }
`;

export const GQL_FETCH_NEWS_AND_RESEARCH_PAGE_ENTRIES = gql`
  query ($skip: Int, $limit: Int, $categorySlug: String, $excludeId: String, $locale: String!, $preview: Boolean!) {
    blogs: postCollection(
      skip: $skip
      limit: $limit
      order: datePublished_DESC
      preview: $preview
      where: {
        languageCode: $locale
        AND: [{ categories: { slug: $categorySlug } }]
        contentfulMetadata: { tags: { id_contains_some: ["vp"] } }
        sys: { id_not: $excludeId }
      }
    ) {
      total
      items {
        sys {
          id
        }
        contentfulMetadata {
          tags {
            name
          }
        }
        slug
        categoriesCollection {
          items {
            title
            slug
          }
        }
        title
        thumbnail {
          url
          width
          height
          title
          description
          contentType
        }
        datePublished
        body {
          json
        }
        featuredImage {
          url
        }
        excerpt
        languageCode
        translationsCollection(limit: 7) {
          items {
            sys {
              id
              firstPublishedAt
            }
            title
            slug
            languageCode
          }
        }
        linkedFrom {
          postCollection(limit: 7) {
            items {
              sys {
                id
              }
              languageCode
              title
              slug
              translationsCollection(limit: 7) {
                items {
                  sys {
                    id
                    firstPublishedAt
                  }
                  languageCode
                  slug
                }
              }
            }
          }
        }
      }
    }

    postCategories: categoryCollection(
      preview: $preview
      order: order_ASC
      where: { languageCode: $locale, belongsTo: "Post", contentfulMetadata: { tags: { id_contains_some: ["vp"] } } }
    ) {
      items {
        sys {
          id
        }
        title
        slug
      }
    }

    productCategories: categoryCollection(
      preview: $preview
      order: order_ASC
      where: { languageCode: $locale, belongsTo: "Product", contentfulMetadata: { tags: { id_contains_some: ["vp"] } } }
    ) {
      items {
        sys {
          id
        }
        title
        slug
      }
    }
  }
`;

export const GQL_FETCH_LATEST_BLOG_ENTRIES = gql`
  query ($locale: String!, $preview: Boolean!) {
    latestBlog: postCollection(
      limit: 1
      preview: $preview
      order: datePublished_DESC
      where: { languageCode: $locale, contentfulMetadata: { tags: { id_contains_some: ["vp"] } } }
    ) {
      items {
        sys {
          id
        }
        slug
        categoriesCollection(limit: 1) {
          items {
            title
            slug
            title
          }
        }
        title
        thumbnail {
          url
          width
          height
          title
          description
          contentType
        }
        datePublished
        excerpt
        languageCode
        translationsCollection(limit: 7) {
          items {
            sys {
              id
              firstPublishedAt
            }
            title
            slug
            languageCode
          }
        }
        linkedFrom {
          postCollection(limit: 7) {
            items {
              sys {
                id
              }
              languageCode
              title
              slug
              translationsCollection(limit: 7) {
                items {
                  sys {
                    id
                    firstPublishedAt
                  }
                  languageCode
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;
